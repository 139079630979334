import * as yup from 'yup';

const couponRedeemForm = {
  order: [
    'code',
    'pin',
    'check_number',
    'submit',
  ],
  fields: {
    code: {
      schema: yup.string().required().max(255).trim(),
      label: "Coupon Code",
      type: "number",
      // placeholder: "e.g. steve@aol.com",
      // helptext: "Your name as you want it to appear to visitors.",
    },
    pin: {
      schema: yup.string().required().max(255).trim(),
      label: "Location PIN",
      type: "number",
      // placeholder: "e.g. steve@aol.com",
      // helptext: "Your name as you want it to appear to visitors.",
    },
    check_number: {
      schema: yup.string().required().max(255).trim(),
      // schema: yup.string().nullable(),
      label: "Check #",
      type: "text",
    },
    submit: {
      type: 'button',
      submit: true,
      text: "Redeem Coupon",
      color: "blue",
      block: true,
    },
  },
};

export default couponRedeemForm;
