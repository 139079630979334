import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import Layout from '../components/layout';
import { pageview } from '../utils/scripts';
import SEO from '../components/seo';
import CouponRedeemForm from '../forms/coupon-redeem';
import { apiUrl, postRequest } from '../utils/request';
import Form from '../components/form/form';
import Accent from '../components/alerts/accent';
import { isLoggedIn } from '../utils/auth';

function CouponRedeemPage() {
  const [error, setError] = useState(false);
  const [code, setCode] = useState('');
  const [init, setInit] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    pageview();

    const parsedQuery = !!window && window.location.search ? queryString.parse(window.location.search) : {};
    setCode(parsedQuery.code || '');
    setInit(true);
  }, []);


  const submitForm = async (data, setErrors) => {
    const url = `${apiUrl}/coupon/redeem/code`;
    setError(false);
    setSuccess(false);
    try {
      await postRequest(url, data);
      setSuccess(true);
    } catch (err) {
      setError(true);
    }
    setErrors({});
  }

  const successBody = <span>The coupon was successfully redeemed! {isLoggedIn() && <Link className="text-indigo-600 hover:text-indigo-900 mx-2" to="/app/dashboard"> Go to dashboard &raquo;</Link>}</span>;

  return (
    <Layout hideHeader hideFooter>
      <SEO title="Redeem a coupon" />
      <section className="">
        <div className="container px-5 py-12 mx-auto flex flex-wrap items-center">
          <div className="lg:w-1/2 md:w-1/2 flex flex-col md:mx-auto w-full f">
            <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8">
              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900 font-headline">
                Redeem a coupon
              </h2>
              {success && <div className="mt-4"><Accent type="success" body={successBody} /></div>}
              {error && <div className="mt-4"><Accent type="error" body="The coupon could not be redeemed.  It may be expired, or the code/pin may have been incorrect." /></div>}
              <p className="text-lg text-gray-800 my-4">Thanks for being a member of Sidewall Club! Our staff
              will give the you PIN # to redeem this code from your account at the time of checkout
              or when your takeout order is picked up.</p>
              <p className="text-lg text-gray-800 mb-4">Thank you again!</p>
            </div>
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-100">
              {!!init && <Form defaults={{ code }} submitForm={submitForm} form={CouponRedeemForm} />}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default CouponRedeemPage;
